import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { Base64 } from 'js-base64';

import { cleanUrl } from '@src/utilities/helpers';
import { useLocationSearchParams } from '@src/hooks/useLocationSearchParams';
import { client } from '@packages/routing';

interface NavigateWithQueryParamsPersistenceFunction {
	navigate: (url: string, options?: NavigateOptions) => void;
	withQueryParams: (url: string) => string;
	toExternalRedirectUrl: () => void;
}

export const useNavigateWithQueryParamsPersistence = (): NavigateWithQueryParamsPersistenceFunction => {
	const location = useLocation();
	const reactNavigate = useNavigate();
	const searchParams = useLocationSearchParams();
	const locationHash = location.hash;

	const withQueryParams = (url: string) => {
		if (locationHash) {
			return cleanUrl(`${url}/${location.search}${locationHash}`);
		}

		return cleanUrl(`${url}/${location.search}`);
	};

	const navigate = (url: string, options?: NavigateOptions) => {
		reactNavigate(withQueryParams(url), options);
	};

	const isValidUrl = (url: string) => {
		try {
			return Boolean(new URL(url));
		} catch {
			return false;
		}
	};

	const { redirectUrl, ...query } = searchParams;

	const toExternalRedirectUrl = () => {
		const urlSearchParams = new URLSearchParams(query);

		if (searchParams.redirectUrl) {
			const parsedRedirectUrl = isValidUrl(redirectUrl) ? redirectUrl : Base64.decode(redirectUrl);

			if (urlSearchParams.get('icid')) {
				urlSearchParams.delete('icid');
			}

			if (urlSearchParams.get('ecid')) {
				urlSearchParams.delete('ecid');
			}

			if (urlSearchParams.size) {
				window.location.replace(cleanUrl(`${parsedRedirectUrl}/?${urlSearchParams}`));
			} else {
				const replaceUrl = locationHash ? cleanUrl(`${parsedRedirectUrl}${locationHash}`) : cleanUrl(parsedRedirectUrl);

				window.location.replace(replaceUrl);
			}
		} else {
			window.location.replace(client.routes.platforms());
		}
	};

	return {
		navigate,
		withQueryParams,
		toExternalRedirectUrl,
	};
};
