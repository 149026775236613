import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ConfigService } from '@src/config/ConfigService';
import { SignupFormValidationSchema } from '@pages/Auth/Signup/signup.schema';
import { IRegisterCredentials, IRegisterFormData } from '@src/types/auth';
import { IReCaptchaActionType } from '@components/ReCaptchaBox/types';
import { useValidatedForm } from '@src/hooks/useValidatedForm';
import { useSignup } from '@src/api/hooks/auth/useSignup';
import { useLegalConsentModal } from '@pages/Legal/LegalConsent/useLegalConsentModal';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

import { AuthLayout } from '@pages/Auth/AuthLayout/AuthLayout';
import BaseButton from '@ui/BaseButton/BaseButton';
import LegalConsentModal from '@pages/Legal/LegalConsent/LegalConsentModal';
import ValidatedFormInput from '@components/ValidatedFormInput/ValidatedFormInput';
import ValidatedFormCheckbox from '@components/ValidatedFormCheckbox/ValidatedFormCheckbox';
import ReCaptchaBox from '@components/ReCaptchaBox/ReCaptchaBox';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { useLocationSearchParams } from '@src/hooks/useLocationSearchParams';

import {
	StyledAuthAction,
	StyledAuthActions,
	StyledAuthCheckbox,
	StyledAuthCheckboxes,
	StyledAuthItem,
	StyledAuthTitle,
} from '@pages/Auth/styles';

export const Signup = () => {
	const params = useParams();
	const { t } = useTranslation();
	const {
		openModal,
		closeModal,
		modalData,
		dataMapper,
		modalDataVersion,
		isLoading: isLegalConsentModalLoading,
	} = useLegalConsentModal();
	const { toExternalRedirectUrl } = useNavigateWithQueryParamsPersistence();
	const { registerCustomer, isLoading: isRegisterLoading } = useSignup();
	const { dataLayer } = useAdobeAnalytics();
	const searchParams = useLocationSearchParams();

	useUsercentricsEffectOnce(() => dataLayer.pageView('registration:main'));

	const { methods, ValidatedFormProvider } = useValidatedForm<IRegisterFormData>({
		schema: SignupFormValidationSchema,
		options: {
			defaultValues: {
				signupPassword: '',
				repeatPassword: '',
				firstName: '',
				lastName: '',
				dataPolicyVersion: true,
				termsOfUseVersion: false,
				rightOfWithdrawalVersion: false,
				isValidRecaptcha: !ConfigService.isRecaptchaEnabled,
			},
		},
		handleFormSubmit: async data => {
			if (!isLegalConsentModalLoading) {
				const finalData: IRegisterCredentials = {
					tokenId: params.tokenId!,
					password: data.signupPassword,
					firstName: data.firstName.trim(),
					lastName: data.lastName.trim(),
					dataPolicyVersion: dataMapper.dataPolicy._version,
					termsOfUseVersion: dataMapper.termsOfUse._version,
					rightOfWithdrawalVersion: dataMapper.rightOfWithdrawal._version,
				};

				if (!!searchParams.requestedBookingId) {
					finalData['deepLinkReference'] = searchParams.requestedBookingId;
				}

				await registerCustomer(finalData, {
					onSuccess: () => {
						dataLayer.event('regConfirmation');

						toExternalRedirectUrl();
					},
				});
			}
		},
	});

	const agreeAndConfirmLegal = () => {
		methods.setValue(modalDataVersion, true);
		methods.clearErrors(modalDataVersion);

		closeModal();
	};

	const validRecaptcha = methods.getValues('isValidRecaptcha');

	if (isLegalConsentModalLoading) {
		return null;
	}

	return (
		<Fragment>
			<AuthLayout
				header={
					<StyledAuthTitle size="small" textAlign="left">
						{t('auth.signUp.title')}
					</StyledAuthTitle>
				}
			>
				<ValidatedFormProvider>
					<StyledAuthItem>
						<ValidatedFormInput localeGroup="auth" name="signupPassword" icon="lock" type="password" />
					</StyledAuthItem>

					<StyledAuthItem>
						<ValidatedFormInput localeGroup="auth" name="repeatPassword" icon="lock" type="password" />
					</StyledAuthItem>

					<StyledAuthItem>
						<ValidatedFormInput localeGroup="auth" name="firstName" icon="personFilled" />
					</StyledAuthItem>

					<StyledAuthItem>
						<ValidatedFormInput localeGroup="auth" name="lastName" icon="personFilled" />
					</StyledAuthItem>

					<StyledAuthCheckboxes>
						<StyledAuthCheckbox>
							<ValidatedFormCheckbox
								localeGroup="auth"
								name="termsOfUseVersion"
								labelLinkHandler={() => openModal('termsOfUse')}
								buttonProps={{
									'data-linktype': 'onPageNavigation',
									'data-linkid': 'reg_terms',
								}}
							/>
						</StyledAuthCheckbox>

						<StyledAuthCheckbox>
							<ValidatedFormCheckbox
								label={
									<span dangerouslySetInnerHTML={{ __html: dataMapper.rightOfWithdrawal.rich_text_editor }}></span>
								}
								localeGroup="auth"
								name="rightOfWithdrawalVersion"
							/>
						</StyledAuthCheckbox>
					</StyledAuthCheckboxes>

					<StyledAuthActions>
						{!validRecaptcha && (
							<ReCaptchaBox
								widgetId="recaptcha-signup"
								action={IReCaptchaActionType.register}
								name="isValidRecaptcha"
							/>
						)}

						<StyledAuthAction>
							<BaseButton type="submit" isLoading={isRegisterLoading} disabled={isRegisterLoading}>
								{t('auth.signUp.title')}
							</BaseButton>
						</StyledAuthAction>
					</StyledAuthActions>
				</ValidatedFormProvider>
			</AuthLayout>

			<LegalConsentModal
				onClose={closeModal}
				onConfirm={() => agreeAndConfirmLegal()}
				modalData={modalData}
				modalDataVersion={modalDataVersion}
			/>
		</Fragment>
	);
};
